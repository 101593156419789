import { snapweb } from "./snapwebContext";

const Content = () => {
  const { isLoading, reload, data, variantData } = snapweb.useSnapWeb();
  return (
    <div className="App">
      <p>{isLoading ? "Loading...." : "Loaded"}</p>
      <button onClick={reload}>FORCE Reload</button>
      DATA:
      <p
        style={{
          whiteSpace: "pre-wrap",
          textAlign: "left",
          fontSize: "16px",
        }}
      >
        {JSON.stringify(data, null, 2)}
      </p>
      VARIANT:
      <p
        style={{
          whiteSpace: "pre-wrap",
          textAlign: "left",
          fontSize: "16px",
        }}
      >
        {JSON.stringify(variantData, null, 2)}
      </p>
    </div>
  );
};

export default Content;

import React from "react";
import "./App.css";
import Content from "./Content";
import { snapweb } from "./snapwebContext";

function App() {
  return (
    <snapweb.SnapWebProvider>
      <Content />
    </snapweb.SnapWebProvider>
  );
}

export default App;

const fromRemote = async config => {
  const response = await fetch("/api/asdf", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      siteId: "id"
    })
  });
  const jsonData = await response.json();
  return jsonData;
};
export default fromRemote;
import fromLocal from "./fromLocal";
import fromRemote from "./fromRemote";
import { loadScript } from "./loadScript";
export default class SnapWeb {
  id = "";
  data = null;
  variantData = null;
  config = null;
  isDev = false;
  isLoading = false;
  _events = {
    data: [],
    networkChange: []
  };
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
    this._events.networkChange.forEach(cb => cb(isLoading));
  }
  setData({
    data,
    variant
  }) {
    this.data = data;
    this.variantData = variant;
    this._events.data.forEach(cb => cb(data, variant));
  }
  constructor(config) {
    if (config) {
      this.init(config);
    }
  }
  on(event, cb) {
    if (!this._events[event]) {
      this._events[event] = [];
    }
    this._events[event].push(cb);
  }
  off(event, cb) {
    if (!this._events[event]) {
      return;
    }
    const index = this._events[event].indexOf(cb);
    if (index > -1) {
      this._events[event].splice(index, 1);
    }
  }
  async init(config, cb) {
    this.config = config;
    this.isDev = !!(window.location.hostname === "localhost" || this.config.development);
    console.log("http://localhost:3302/dist.js");
    console.log("http://localhost:3301/dist.js");
    if (this.isDev && "http://localhost:3302/dist.js") {
      loadScript("http://localhost:3302/dist.js", "snapweb-cms");
    }
    if ("http://localhost:3301/dist.js") {
      loadScript("http://localhost:3301/dist.js", "snapweb-rm");
    }
    await this.load(cb);
  }
  async load(cb) {
    this.setIsLoading(true);
    const data = await (this.isDev ? fromLocal(this.config) : fromRemote(this.config));
    this.setData(data);
    cb?.(this.data, this.variantData);
    this.setIsLoading(false);
  }
}
import SnapWeb, { SnapWebConfig } from "@snapweb/core";
import { JSONSchema7 } from "json-schema";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  FC,
} from "react";
import {
  JSONSchemaToType,
  PresetKeys,
  PresetTypes,
  PresetTypesfromKey,
} from "@snapweb/schema";

//todo: TypeOfSnapWebSchema not resolved properly when imported from @snapweb/schema
export type TypeOfSnapWebSchema<
  S extends JSONSchema7 = JSONSchema7,
  P extends PresetKeys = []
> = PresetTypesfromKey<P> & PresetTypes["_default"] & JSONSchemaToType<S>;
export interface SnapWebContextValue<T, U> {
  data: T | null;
  variantData: U | null;
  isLoading: boolean;
  reload: () => void;
}

export const createSnapWebContext = <
  S extends JSONSchema7,
  V extends JSONSchema7,
  P extends PresetKeys
>(
  config: SnapWebConfig<S, V, P>
) => {
  type T = TypeOfSnapWebSchema<S, P>;
  type U = JSONSchemaToType<V>;

  //@ts-ignore 2589
  const SnapWebContext = createContext<SnapWebContextValue<T, U>>({
    data: null,
    isLoading: false,
    reload: () => {},
  });

  const SnapWebProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const snapwebRef = useRef(new SnapWeb<S, V, P, T>());
    const [data, setData] = useState<T | null>(null);
    const [variantData, setVariantData] = useState<U | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleData = useCallback((data: T, variant: U) => {
      setData(data);
      setVariantData(variant);
    }, []);

    const handleNetworkChange = useCallback((loading: boolean) => {
      setIsLoading(loading);
    }, []);

    useEffect(() => {
      const snapweb = snapwebRef.current;
      (window["SnapWeb"] as any) = snapwebRef.current;
      snapweb.on("data", handleData);
      snapweb.on("networkChange", handleNetworkChange);
      snapweb.init(config);

      return () => {
        snapweb.off("data", handleData);
        snapweb.off("networkChange", handleNetworkChange);
      };
    }, [config, handleData, handleNetworkChange]);

    const reload = useCallback(async () => {
      await snapwebRef.current.load();
    }, []);

    return (
      <SnapWebContext.Provider value={{ data, variantData, isLoading, reload }}>
        {children}
      </SnapWebContext.Provider>
    );
  };

  const useSnapWeb = () => useContext(SnapWebContext);

  return { SnapWebProvider, useSnapWeb };
};

export function loadScript(url, id) {
  const scripts = document.getElementsByTagName("script");
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === url) {
      console.log(`Script already loaded: ${url}`);
      return;
    }
  }
  if (document.getElementById(id)) {
    console.log(`Script already loaded: ${id}`);
    return;
  }
  const script = document.createElement("script");
  script.src = url;
  script.id = id;
  script.type = "text/javascript";
  document.head.appendChild(script);
}
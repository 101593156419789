import { createSnapWebContext } from "@snapweb/react";

export const snapweb = createSnapWebContext({
  schema: {
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      data: {
        title: "Site Data",
        description: "Test data of example-react",
        type: "object",
        properties: {
          heading1: { type: "string" },
          description: { type: "string" },
          status: { type: "string", enum: ["pending", "active", "closed"] },
          images: {
            type: "array",
            items: {
              type: "string",
              format: "image",
            },
          },
        },
        required: ["age", "free", "name"],
      },
    },
  },
  presets: ["menu", "contact", "people", "news"],
  development: {
    fileServerUrl: "http://localhost:4000/upload",
  },
  variantSchema: {
    properties: {
      variantAge: { type: "number" },
      variantName: { type: "string" },
    },
  },
});
